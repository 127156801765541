import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AuthActions from '../actions/auth.actions';
import * as LoginActions from '../actions/login.actions';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { AuthApiService, UsersApiService } from '@src/app/core/api';
import { catchError, of } from 'rxjs';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { AuthService } from '@src/app/core/services/auth.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthEffects {
  private localStorageService = inject(LocalStorageService);

  constructor(
    private actions$: Actions,
    private authApi: AuthApiService,
    private usersApi: UsersApiService,
    private location: Location,
    public authService: AuthService,
    private router: Router,
    private translate: TranslateService,
  ) {}

  loginCheck$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginCheck),
      exhaustMap(() =>
        this.usersApi.usersControllerGetCurrentUser().pipe(
          map(user => AuthActions.loginCheckSuccess({ user })),
          catchError(err => {
            if (this.localStorageService.getToken(true)) {
              this.authService.resetToken();
              return 'reset called';
            } else {
              // console.log('Current URL:', this.location.path().includes('auth'));
              // console.log(this.router.url)
              if (!this.location.path().includes('auth')) {
                return of(AuthActions.logout());
              } else {
                return of(AuthActions.loginCheckSuccess({ user: null }));
              }
            }
          }),
        ),
      ),
    );
  });

  loginCheckSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginCheckSuccess),
        tap(() => {
          if (this.router.url === '/login') {
            this.router.navigate(['/']);
          }
        }),
      );
    },
    { dispatch: false },
  );

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.login),
      map(action => action.data),
      exhaustMap(data =>
        this.authApi.authControllerLogin(data).pipe(
          map((response: any) => {
            this.localStorageService.setToken(String(response.refreshToken), true);
            this.localStorageService.setToken(String(response.accessToken));
            this.authService.showMessage(
              this.translate.instant('COMPONENTS.LOGIN.FIELDS.USER_LOGGED_IN'),
              'success',
            );
            return AuthActions.loginSuccess({
              user: response.user,
              accessToken: response.accessToken,
            });
          }),
          catchError(({ error }) =>{
            this.authService.showMessage(
              this.translate.instant('COMPONENTS.LOGIN.FIELDS.UNABLE_TO_LOGIN'),
              'error',
            );
            return of(AuthActions.loginFailure({ error: error.message }))
        }),
        ),
      ),
    );
  });

  loginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap(() => this.router.navigate(['/'])),
      );
    },
    { dispatch: false },
  );

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => this.router.navigate(['/'])),
        exhaustMap(() =>
          this.authApi
            .authControllerLogout({ refreshToken: this.localStorageService.getToken(true) })
            .pipe(catchError(() => of(null))),
        ),
      );
    },
    { dispatch: false },
  );
}
